import * as React from 'react'
import isEqual from 'lodash.isequal'
import md5 from 'md5'

import Analytics from 'src/utils/analytics'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import SEO from 'src/components/SEO/SEO'
import globalMessages from 'src/globalMessages'
import { getShortISODate } from 'src/utils/misc'

import styles from './receipt.module.scss'

type Props = {
  location: any
  pageContext: {
    canonical: string
  }
}

type State = {
  pending: boolean
  ticked: boolean
  tracked: boolean
}

const getUserId = (email: string) => {
  return md5(email)
}

// https://stackoverflow.com/a/29475321
const addBusinessDays = (startingDate: Date, daysToAdjust: number) => {
  const newDate = new Date(startingDate.valueOf())
  let businessDaysLeft
  let isWeekend

  // short-circuit no work; make direction assignment simpler
  if (daysToAdjust === 0) {
    return startingDate
  }
  const direction = daysToAdjust > 0 ? 1 : -1

  // Move the date in the correct direction
  // but only count business days toward movement
  businessDaysLeft = Math.abs(daysToAdjust)
  while (businessDaysLeft) {
    newDate.setDate(newDate.getDate() + direction)
    isWeekend = newDate.getDay() in { 0: 'Sunday', 6: 'Saturday' }
    if (!isWeekend) {
      businessDaysLeft--
    }
  }
  return newDate
}

const getEstimatedDelivery = (order: any) => {
  const country =
    order && order.shippingAddress ? order.shippingAddress.countryName : ''
  let expDeliveryDaysForCountry = '4'
  const expDeliveryDays = 0
  if (country && expDeliveryDays) {
    if (expDeliveryDaysForCountry) {
      expDeliveryDaysForCountry =
        expDeliveryDaysForCountry.split('-')[1] ||
        expDeliveryDaysForCountry.split('-')[0] ||
        '4'
    } else {
      expDeliveryDaysForCountry = '4'
    }
  }
  const expDeliveryDate = addBusinessDays(
    new Date(),
    parseInt(expDeliveryDaysForCountry, 10)
  )
  return getShortISODate(expDeliveryDate)
}

const trackCompletedOrder = (order: any) => {
  const { discounts, totals } = order
  const {
    shippingPriceAsNumber: shipping,
    grandTotalPriceTaxAsNumber: tax,
    grandTotalPriceAsNumber,
  } = totals
  const revenue = grandTotalPriceAsNumber - tax
  const eePurchase: any = {
    actionField: {
      discount: discounts.discountAsNumber,
      id: order.order,
      revenue: revenue.toString(),
      shipping: shipping.toString(),
      tax: tax.toString(),
    },
    products: [],
  }
  if (discounts.vouchers) {
    eePurchase.actionField.coupon = Object.keys(discounts.vouchers)[0]
  }

  // order.order is for Klarna, where we don’t necessarily get an email in return data
  const userEmail = order.address.email || order.order
  const virtusizeOrder: {
    items: any[]
    orderId: string
    userId: string
  } = {
    items: [],
    orderId: order.order,
    userId: getUserId(userEmail),
  }

  // Add all individual items
  const orderItems = order.items
  // var priceVariable = order.totals.taxDeducted ? 'priceEachWithoutTaxAsNumber' : 'priceEachAsNumber'
  const priceVariable = 'priceEachWithoutTaxAsNumber'
  for (let i = 0; i < orderItems.length; ++i) {
    let productImage =
      order.media && order.media.standard ? order.media.standard[0] : ''
    let productCategory =
      (orderItems[i].product &&
        orderItems[i].product['google_merchant_category']) ||
      ''

    if (!productImage) productImage = ''
    if (!productCategory) productCategory = ''

    eePurchase.products.push({
      category: productCategory,
      id: orderItems[i].sku,
      name: orderItems[i].product.name,
      price: orderItems[i][priceVariable].toString(),
      quantity: orderItems[i].quantity,
      sku: orderItems[i].sku,
      variant: orderItems[i].size,
    })

    const size = orderItems[i].size || orderItems[i].size.replace(/[WL]/g, '')

    virtusizeOrder.items.push({
      currency: order.currency,
      imageUrl: productImage,
      productId: orderItems[i].sku.substr(0, 6),
      quantity: orderItems[i].quantity,
      size: size,
      unitPrice: orderItems[i][priceVariable],
    })
  }

  // Send the data
  Analytics.track('purchase', {
    currency: order.currency,
    purchase: eePurchase,
  })
}


class Receipt extends React.Component<Props, State> {
  state = {
    pending: true,
    ticked: false,
    tracked: false,
  }
  
  componentDidMount() {
    const { location } = this.props
    let order = null
    if (location && location.state) {
      order = location.state.order || null
    }
    if (order) {
      this.init(order)
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { location } = this.props
    const { tracked } = this.state
    let order = null
    if (location && location.state) {
      order = location.state.order || null
    }
    if (order && !tracked) {
      if (!isEqual(location, prevProps.location)) {
        this.init(order)
      }
    }
  }

  init(order: any) {
    // console.log(order); /* eslint-disable-line */
    trackCompletedOrder(order)
    this.setState({
      tracked: true,
    })
  }

  render(): JSX.Element {
    const { location, pageContext } = this.props
    const { ticked } = this.state
    const { canonical } = pageContext

    let order = null
    if (location && location.state) {
      order = location.state.order || null
    }

    const seoData = {
      canonical,
      noIndex: true,
      seoTitle: globalMessages.receipt.defaultMessage,
      bodyClass: 'aino-route-receipt',
    }

    return (
      <React.Fragment>
        <div className={styles.wrapper}>
          <SEO {...seoData}>
            <script
              async
              defer
              src="https://apis.google.com/js/platform.js?onload=renderOptIn"
            ></script>
          </SEO>
          <div className={styles.content}>
            {order ? (
              <div className={styles.thankYou}>
                <div className={styles.greenBar}></div>
                <h1>
                  {globalMessages.thankYou.defaultMessage}
                </h1>
                <strong className={styles.subLine}>
                  {globalMessages.successfullySubmitted.defaultMessage}
                </strong>
                <p>
                  {globalMessages.referenceIdForThisOrder.defaultMessage}<span className={styles.orderId}>{order.order}</span>
                </p>
                <p>
                  {globalMessages.youWillShortlyBeContacted.defaultMessage}
                </p>
                <p>
                  {globalMessages.forQuestions.defaultMessage}
                  {' '}
                  <LinkComponent to={'/faq'}>
                    FAQ
                  </LinkComponent>
                </p>
              </div>
            ) : (
              <div className={styles.thankYou}>
                <h1>
                  {globalMessages.notFound.defaultMessage}
                </h1>
                <strong className={styles.subLine}>
                  {globalMessages.couldNotFindReceipt.defaultMessage}
                </strong>
                <p>
                  {globalMessages.forQuestions.defaultMessage}
                  {' '}
                  <LinkComponent to={'/faq'}>
                    FAQ
                  </LinkComponent>
                </p>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Receipt
